<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Listados de Ubicaciones</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              @click="$refs.createOrUpdate.showModal()"
              >Agregar Nuevo</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" class="my-3">
                <b-form-group
                  label="Buscar:"
                  label-for="filter-input"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-input
                      :disabled="
                        !Array.isArray(getLocation) || getLocation.length === 0
                          ? true
                          : false || isBusy == true
                      "
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Buscar ubicaciones"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12" class="table-responsive">
                <b-table
                  hover
                  :items="getLocation"
                  :fields="columns"
                  :filter="filter"
                  :busy="
                    !Array.isArray(getLocation) || getLocation.length === 0
                      ? true
                      : false || isBusy == true
                  "
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>Sin registros</span>
                    </div>
                  </template>
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input
                      type="text"
                      v-model="data.item.name"
                      v-else
                      class="form-control"
                    />
                  </template>
                  <template v-slot:cell(action)="data">
                    <div v-if="data.item.name !== 'Área de Recepción'">
                      <div v-if="!data.item.editable">
                        <b-button
                          v-b-tooltip.hover
                          title="Editar"
                          variant=" iq-bg-warning mr-1 mb-1"
                          size="sm"
                          @click="$refs.createOrUpdate.showModal(data.item)"
                          ><i class="ri-ball-pen-fill ml-1"></i
                        ></b-button>
                        <b-button
                          v-b-tooltip.hover
                          title="Eliminar"
                          variant=" iq-bg-danger mr-1 mb-1"
                          size="sm"
                          @click="showMsgBoxTwo(data.item)"
                          ><i class="ri-delete-bin-line ml-1"></i
                        ></b-button>
                      </div>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modals ref="createOrUpdate" />
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapActions, mapGetters } from 'vuex'
import modals from '@/components/core/modals/LocationModals'

export default {
  name: 'ListUbication',
  components: {
    modals
  },
  async created () {
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index()
    await this.listUbication()
  },
  methods: {
    ...mapActions({
      listUbication: 'ubication/listUbication',
      destroyLocation: 'ubication/destroyUbication'
    }),
        showMsgBoxTwo(item) {
        this.boxTwo = ''
        this.$bvModal.msgBoxConfirm('¿Desea eliminar este registro?.', {
          title: 'Confirmación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Si',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
         .then(value => {
            value ? this.remove(item) : ''
          })
          .catch(err => {
            console.log(err)
          })
      },
    async remove (item) {
      try {
        this.$store.commit('run')
        await this.destroyLocation(item.id)
        this.$store.commit('stop')
        core.showSnackbar('success', 'Registro eliminado correctamente')
      } catch (error) {
        this.$store.commit('stop')
        const errors = error.response.data.errors
        for (const key in errors) {
          core.showSnackbar('error', errors[key][0])
          return false
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getLocation: 'ubication/getUbication'
    })
  },
  data () {
    return {
      columns: [
        { label: 'Nombre', key: 'name', class: 'text-center' },
        { label: 'Acciones', key: 'action', class: 'text-center' }
      ],
      items: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          text: 'Listado de ubicaciones',
          active: true
        }
      ],
      filter: null,
      isBusy: false
    }
  }
}
</script>
